<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-xl-3">
          <label style="margin-right: 5px">{{
            this.$t("emploi.trimestre")
          }}</label>
          <a-select
            :placeholder="$t('paiement.selectionnerMois')"
            style="width: 100px; margin-left: 15px; margin-right: 20px"
            @change="(val) => selectedTrimesterChanged(val)"
            default-value="1"
          >
            <a-select-option
              v-for="trimester in ['1', '2', '3']"
              :key="trimester"
              :value="trimester"
              >{{ trimester }}</a-select-option
            >
          </a-select>
        </div>

        <div class="col-xl-3">
          <label style="margin-right: 15px">{{
            this.$t("menu.bulletin")
          }}</label>

          <a-button
            type="primary"
            :loading="printLoading"
            :disabled="isPrintDisabled"
            @click="prePrintOneBulletin()"
          >
            <a-icon v-if="!printLoading" type="file-pdf" />
            {{ $t("action.imprimer") }}
          </a-button>
          <br />
        </div>
      </div>
      <a-divider></a-divider>
      <div class="row">
        <div class="col-xl-8">
          <div class="card" v-if="studentId && classRoomId">
            <div class="data-table">
              <a-table
                :columns="columns"
                :data-source="subjectList"
                :loading="tableLoading"
                :locale="{ emptyText: $t('scolarite.aucunDonnee') }"
                :scroll="{ x: 'max-content' }"
              >
                <template slot="moduleG" slot-scope="text, record">
                  <a-row type="flex" style="align-items: center">
                    <a-col :span="8">
                      <span>{{ record.moduleName }}</span>
                    </a-col>
                    <a-col :span="4" style="display: flex; align-items: center">
                      <a-tag
                        :color="getSubmoduleColor(record.subModule._id)"
                        v-if="record.subModule"
                        style="margin-left: 8px"
                      >
                        {{ record.subModule.label }}
                      </a-tag>
                    </a-col>
                  </a-row>
                </template>

                <template slot="note" slot-scope="text, record">
                  <div>
                    <!-- Display main subject mark -->
                    <div v-if="record.mark !== null">
                      <span class="mark-box">
                        <span>{{ record.mark }}</span
                        >/<span>20</span>
                      </span>
                    </div>

                    <!-- Display subsubject marks -->
                    <div
                      v-for="item in record.subsubjectData"
                      :key="item.name"
                      class="subsubject-row"
                      :class="{ danger: item.mark < 0 }"
                    >
                      <span class="ant-input-wrapper">
                        <span class="ant-input-group-addon">{{
                          item.name
                        }}</span>
                        <span class="ant-input-group-addon">
                          <span>{{
                            item.mark !== null ? item.mark : "--"
                          }}</span
                          >/
                          <span>{{ item.maxMark }}</span>
                        </span>
                      </span>
                    </div>
                  </div>
                </template>

                <template slot="name" slot-scope="text">
                  <span class="arabic-font">{{ text }}</span>
                </template>

                <div
                  slot="filterDropdown"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px"
                >
                  <a-input
                    v-ant-ref="(c) => (searchInput = c)"
                    :placeholder="`${$t('personnel.chercher')} ${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="
                      (e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  />
                  <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                    >{{ $t("paiement.chercher") }}</a-button
                  >
                  <a-button
                    size="small"
                    style="width: 90px"
                    @click="() => handleReset(clearFilters)"
                    >{{ $t("action.reinitialiser") }}</a-button
                  >
                </div>
                <a-icon
                  slot="filterIcon"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template
                  slot="customRender"
                  slot-scope="text, record, index, column"
                >
                  <span
                    v-if="searchText && searchedColumn === column.dataIndex"
                  >
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${searchText})|(?=${searchText})`,
                            'i'
                          )
                        )"
                    >
                      <mark
                        v-if="
                          fragment.toLowerCase() === searchText.toLowerCase()
                        "
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>{{ text }}</template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="card" v-if="studentId && classRoomId">
            <div class="card-body">
              <div style="font-size: 1.75rem; color: black">
                {{ $t("bulletin.moyenneTrimestre", { moyenne: moyenne }) }}
              </div>
              <div>
                {{
                  $t("bulletin.moyenneMax", {
                    moyenne: bulletin.maxMoyenne,
                  })
                }}<br />
                {{
                  $t("bulletin.moyenneMin", {
                    moyenne: bulletin.minMoyenne,
                  })
                }}
              </div>
            </div>
          </div>

          <div class="card" v-if="studentId && classRoomId">
            <marksChart
              :data="marks"
              :maxmin="marksMaxMin"
              :subjects="subjects"
            />
          </div>

          <div class="card" v-if="studentId && classRoomId">
            <moduleChart
              :data="marks"
              :subjects="subjects"
              :modules="Modules"
              :maxMinMoy="maxMinMoy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";

import marksChart from "@/components/bulletin/studentMarks/marksChart";
import moduleChart from "@/components/bulletin/studentMarks/moduleChart";
import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { notification, Modal } from "ant-design-vue";
import moment from "moment";
import bulletinPedaHelper from "@/views/bulletinv2/studentMarks/scripts/bulletinHeplers/peda";

export default {
  computed: {
    ...mapState(["settings", "user"]),

    isPrintDisabled() {
      return this.subjectList.length === 0 || this.printLoading;
    },
  },
  props: {
    studentId: {
      required: true,
    },
    classRoomId: {
      required: true,
    },
    classRoomLevel: {
      required: true,
    },
    studentDetails: {
      required: true,
    },
  },
  components: { marksChart, moduleChart },
  async created() {
    window.addEventListener("beforeunload", this.confirmLeaveReload);

    apiClient
      .get("/certification/peda")
      .then((res) => {
        this.certifParams = res.data;
      })
      .catch((e) => {
        console.log(e);
      });

    this.filtredTable = this.activeData;

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];

    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    this.schoolarYearName = end + " / " + start;

    this.schoolType = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0].type;
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });
  },
  async mounted() {
    await this.fetchStudentMarks();
  },
  data() {
    return {
      certifParams: [],
      printLoading: false,
      visibleClassnameList: false,
      visibleStudentList: false,
      visibleBulletinDate: false,
      bulletinDate: new Date(),
      moyenne: "--",
      maxMoyenne: "--",
      minMoyenne: "--",
      bulletin: { maxMoyenne: "--", minMoyenne: "--", moyenne: "--" },
      schoolType: null,
      schoolDetails: {},
      filtredTable: [],
      subjects: [],
      selectedStudentName: "",
      studentLoading: false,
      selectedTrimester: "1",
      tableLoading: false,
      activeData: [],
      studentMarks: [],
      markRefs: [],
      marksMaxMin: [],
      bulletinData: [],
      maxMinMoy: {},
      subsubjectData: [],
      subjectList: [],
      Modules: [],
      subjects: [],
      markData: [],
      marks: [],
      submoduleColors: {},
      subModuleList: [],
      selectedLevel: null,
      classroomMarksStats: {},
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [
        {
          title: this.$t("bulletin.matieres"),
          dataIndex: "name",
          key: "name",
          scopedSlots: {
            customRender: "name",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("smartSchedule.module"),
          dataIndex: "moduleG",
          key: "moduleG",
          scopedSlots: {
            customRender: "moduleG",
          },
          onFilter: (value, record) =>
            record.moduleG
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("bulletin.note"),
          dataIndex: "note",
          scopedSlots: { customRender: "note" },
        },
      ],
      listModules: [],
      selectedClassName: "",
    };
  },
  methods: {
    async selectedTrimesterChanged(val) {
      this.selectedTrimester = val;
      this.tableLoading = true;
      this.subjectList = [];
      this.marks = [];
      this.Modules = [];
      this.subjects = [];
      this.moyenne = "--";
      this.bulletin.maxMoyenne = "--";
      this.bulletin.minMoyenne = "--";

      try {
        await this.fetchStudentMarks();
      } catch (error) {
        console.error("Error fetching data for trimester:", error);
      } finally {
        this.tableLoading = false;
      }
      await this.updateCertifParams();
    },
    moment,
    async sleep(timeout) {
      await new Promise((r) => setTimeout(r, timeout));
    },
    async chooseBulletinDate() {
      this.visibleBulletinDate = true;
      return new Promise(async (res) => {
        while (this.visibleBulletinDate) {
          await this.sleep(500);
        }
        res();
      });
    },
    async updateCertifParams() {
      await apiClient
        .get("/certification/peda")
        .then((res) => {
          this.certifParams = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getSubmoduleColor(submoduleId) {
      if (!this.submoduleColors[submoduleId]) {
        const colors = [
          "cyan",
          "red",
          "orange",
          "green",
          "blue",
          "purple",
          "pink",
        ];
        const randomColor = colors[Math.floor(Math.random() * colors.length)];
        this.$set(this.submoduleColors, submoduleId, randomColor);
      }
      return this.submoduleColors[submoduleId];
    },

    async fetchStudentMarks() {
      await apiClient
        .get(
          "/bulletin/v2/bt/MP/" + this.studentId + "/" + this.selectedTrimester
        )
        .then(({ data }) => {
          this.bulletin = data;
          this.moyenne = data.moyenne;
          this.bulletin.maxMoyenne = data.maxMoyenne;
          this.bulletin.minMoyenne = data.minMoyenne;

          if (!this.bulletin.modules || this.bulletin.modules.length === 0) {
            console.warn("No modules found in the bulletin data.");
            return;
          }
          this.subjects = data.modules.flatMap((module) =>
            module.subjects.map((subject) => ({
              _id: subject._id,
              name: subject.name,
            }))
          );

          this.subjectList = data.modules.flatMap((module) => {
            return module.subjects.map((subject) => {
              const subsubjectData =
                subject.subSubjectMarks?.map((subSubject) => ({
                  name: subSubject.name,
                  mark:
                    subSubject.mark === "--"
                      ? null
                      : parseFloat(subSubject.mark),
                  maxMark: parseFloat(subSubject.maxMark),
                })) || [];

              return {
                _id: subject._id,
                name: subject.name,
                moduleName: module.name,
                subModule: module.submodules
                  ? module.submodules.find(
                      (sub) => sub._id === subject.submoduleID
                    )
                  : null,
                mark: subject.mark ? parseFloat(subject.mark) : null,
                maxMark: 20,
                subsubjectData,
              };
            });
          });
          this.marks = data.modules.flatMap((module) =>
            module.subjects.map((subject) => ({
              subject: subject._id,
              mark: parseFloat(subject.mark) || 0,
            }))
          );

          this.markData = data.modules.flatMap((module) =>
            module.subjects.flatMap((subject) => {
              const baseMark = {
                student: this.studentId,
                subjectId: subject._id,
                moduleId: module._id,
                subModuleId: subject.submoduleID || null,
                mark: subject.mark ? parseFloat(subject.mark) : null,
                maxMark: 20,
              };

              if (subject.subSubjectMarks) {
                return subject.subSubjectMarks.map((subSubject) => ({
                  ...baseMark,
                  subSubjectName: subSubject.name,
                  mark:
                    subSubject.mark === "--"
                      ? null
                      : parseFloat(subSubject.mark),
                  maxMark: parseFloat(subSubject.maxMark),
                }));
              }

              return baseMark;
            })
          );

          this.marksMaxMin = data.modules.flatMap((module) =>
            module.subjects.map((subject) => ({
              _id: subject._id,
              min: subject.min || 0,
              max: subject.max || 20,
            }))
          );

          this.maxMinMoy = {
            moyModules: data.modules.reduce((acc, module) => {
              acc[module._id] = {
                max: parseFloat(module.maxMoyenne) || 20,
                min: parseFloat(module.minMoyenne) || 0,
              };
              return acc;
            }, {}),
          };

          this.Modules = data.modules.map((module) => ({
            _id: module._id,
            name: module.name,
            moyenne: module.moyenne,
            subjects: module.subjects.map((subject) => ({
              _id: subject._id,
              name: subject.name,
            })),
          }));
        })
        .catch((error) => {
          console.error("Error fetching bulletin data:", error);
        });
    },
    async created() {
      window.addEventListener("beforeunload", this.confirmLeaveReload);
      this.tableLoading = true;
      let project = {
        _id: 1,
        name: 1,
        level: 1,
      };
      project[this.bulletinType(2)] = 1;
      await apiClient
        .post("/classrooms/filter", {
          query: {
            status: "active",
          },
          aggregation: {
            $project: project,
          },
        })
        .then((res) => (this.classes = res.data));
      apiClient
        .get(`/certification/peda`)
        .then((res) => {
          this.certifParams = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
      this.tableLoading = false;
      this.filtredTable = this.activeData;

      this.schoolDetails = this.user.building.filter(
        (el) => el.dbName == this.settings.activeBuilding
      )[0];

      let { start, end } = this.settings.schoolarYears.find(
        (el) => el._id == this.settings.activeSchoolarYear
      );

      start = new Date(start).getFullYear();
      end = new Date(end).getFullYear();

      this.schoolarYearName = end + " / " + start;

      this.schoolType = this.user.building.filter(
        (el) => el.dbName == this.settings.activeBuilding
      )[0].type;
      if (!this.schoolType)
        notification.warning({
          message: this.$t("bulletin.typeIntrouvable"),
          description: this.$t("bulletin.saisirSchoolType"),
        });
    },

    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async prePrintOneBulletin() {
      this.printLoading = true;

      const config = {
        selectedLevel: this.selectedLevel || this.classRoomLevel || 0,
        schoolDetails: this.schoolDetails || {},
        selectedStudent: this.studentId || this.selectedStudent || "Unknown",
        bulletinDate: this.bulletinDate || new Date(),
        certifs: this.certifParams,

        schoolarYearName: this.schoolarYearName || "Unknown Year",
        type: "peda",
        bulletinType: "MP",
        selectedTrimester: this.selectedTrimester || "1",

        settings: this.settings || {},
      };

      try {
        await bulletinPedaHelper.printBulletin(false, config);
      } catch (error) {
      } finally {
        this.printLoading = false;
      }
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped>
.arabic-font {
  font-size: 14px !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

.success .ant-btn-danger {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}
.mark-box {
  display: inline-block;
  background-color: #f5f5f5;
  padding: 3px 7px;
  border-radius: 3px;
  font-size: 14px;
  margin: 0 2px;
  border: 1px solid #ddd;
}

.subsubject-row {
  margin-top: 8px;
}

.danger {
  color: red;
}
</style>
